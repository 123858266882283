export const provinceData = {
  data: [
    {
      "region": "全部"
    },
    {
      "region": "北京"
    },
    {
      "region": "天津"
    },
    {
      "region": "上海"
    },
    {
      "region": "重庆"
    },
    {
      "region": "黑龙江"
    },
    {
      "region": "辽宁"
    },
    {
      "region": "吉林"
    },
    {
      "region": "河南"
    },
    {
      "region": "河北"
    },
    {
      "region": "山东"
    },
    {
      "region": "山西"
    },
    {
      "region": "陕西"
    },
    {
      "region": "内蒙古"
    },
    {
      "region": "宁夏"
    },
    {
      "region": "青海"
    },
    {
      "region": "新疆"
    },
    {
      "region": "云南"
    },
    {
      "region": "安徽"
    },
    {
      "region": "福建"
    },
    {
      "region": "江苏"
    },
    {
      "region": "江西"
    },
    {
      "region": "湖北"
    },
    {
      "region": "湖南"
    },
    {
      "region": "浙江"
    },
    {
      "region": "贵州"
    },
    {
      "region": "广东"
    },
    {
      "region": "四川"
    },
    {
      "region": "广西"
    },
    {
      "region": "西藏"
    },
    {
      "region": "海南"
    }
  ]
}

