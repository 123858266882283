<template>
    <div style="width:100%">
        <el-table ref="table" width="910" :data="tableData" border stripe  :header-cell-style="{background:'#F6F6F6',color:'#333333'}"
                v-loading="loading" @row-click="openDetails">
            <el-table-column prop="title" label="标题" width="380" ></el-table-column>
            <el-table-column prop="answer" label="留言内容" width="380" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="region" label="纳税人所属地"></el-table-column>
            <el-table-column label="答复时间">
                <template slot-scope="scope">
                    <span>{{ utils.formatDate(scope.row.answerDate) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="60">
                <template>
                    <el-button size="mini" type="text">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>

</template>

<script>

import {getStore, setStore,getLocalStore} from '@/util/store'
import {mapMutations} from 'vuex'
export default {
    props: {
        tableData:Array,
        loading:Boolean
    },
    data() {
        return {
        }
    },
    created(){
    },
    methods:{
        ...mapMutations(["addPath", "addMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'policyCaliber',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        openDetails(row){
            this.getReport(2,'table','detailBtn');
            this.addPath(row.title);
            this.addMenuList("/caliberDetail");
            setStore("currentMenu", "/caliberDetail");
            this.$router.push({path: "/caliberDetail",query:{id:row.id}});
        }
    }
}
</script>

<style scoped  lang="less">
/deep/tbody{
    cursor: pointer;
}
</style>