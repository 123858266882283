<template>
    <!--政策口径-->
    <div class="base-container">
        <div class="main-wrapper">
            <el-container style="height: 100%; border: 1px solid #eee; background:#fff">

                <el-header>
                        <div class="search_box">
                            <p class="header_title">政策口径查询</p>
                        </div>
                </el-header>
                    <el-main>
                        <div>
                            <div class="input_box">
                                <p>关键字查询：</p>
                                <el-input class="search-content" prefix-icon="el-icon-search" size="small" v-model="keyword" placeholder="输入关键字" ></el-input>
                                <p>纳税人所在地：</p>
                                <el-select @change="selectChange" v-model="province" filterable :filter-method="searchCity" placeholder="全部">
                                    <el-option
                                    v-for="(item,index) in proData"
                                    :key="index"
                                    :label="item.region"
                                    :value="item.region">
                                    </el-option>
                                </el-select>
                                <el-button class="search_btn" type="primary" size="small" @click="searchClick()">搜索</el-button>
                            </div>
                        </div>
                        <div class="chart_box">
                            <div class="table-box">
                                <policyCaliber :tableData="tableData" :loading="loading"></policyCaliber>
                            </div>
                            <div class="page-box">
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    background
                                    layout="prev,pager,next,total,sizes,jumper"
                                    :page-sizes="[20, 50, 100]"
                                    :total="total"
                                    :current-page="pageNum">
                                </el-pagination>
                            </div>
                        </div>
                    </el-main>
            </el-container>
        </div>
    </div>
</template>

<script>

import {getStore,setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex";
import policyCaliber from '@/views/policyCaliber/table.vue'
import {provinceData} from './province';//数据源
export default {
    name: "index",
    components: {
        policyCaliber:policyCaliber
    },

    data() {
        return {
          tableData:[],
          keyword:'',
          loading: false,
          total:0,
          pageNum:1,
          themeId:'',
          lawType:'',
          pageSize:20,
          proData: [],
          province:''
        }
    },
    created() {
        this.getReport(0,'','');
        this.proData = provinceData.data;
        this.getSearchData();

    },
    destroyed(){
        this.getReport(1,'','');
    },
    mounted() {
    },
    methods: {
        ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'policyCaliber',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        searchClick(){
            this.getReport(2,'search','searchBtn');
            this.pageNum = 1;
            this.getSearchData();
            // this.province = ''
        },
        getSearchData(){
            this.tableData = [];
            this.loading = true;
            let pro = this.province;
            if(this.province == '全部'){
                pro = '';
            }
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/policy/pageListThemeLaw',
                 
                data:{
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    keyword: this.keyword,
                    region: pro
                }
            }).then((res) => {
                this.tableData = res.data.data.records;
                this.total = res.data.data.total;
                this.loading = false;
            })
        },
        handleSizeChange(val){
            this.pageSize = val;
            this.pageNum = 1;
            this.getSearchData();
           
        },
        handleCurrentChange(val){
            this.getReport(2,'table',val);
            this.pageNum = val;
            this.getSearchData();
            
        },
        selectChange(val){
            this.province = val;
            this.pageNum = 1;
            // this.getSearchData()
        },
        searchCity(val){
            this.province = val;

        }
        
       
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 0;
}
.el-header{
  border-bottom: 1px solid #EBEBEB;
}

.search_box{
    height: 60px;
    line-height: 60px;
    width: 100%;
}
.search_tag{
    font-size: 14px;
    font-weight: 400;
    color: #185BCE;
    margin-right: 16px;
    cursor: pointer;
}
.input_box{
    width: 100%;
    height: 32px;
    margin-bottom: 16px;
    >p{
        float: left;
        line-height: 32px;
    }
    .el-input{
        float: left;
        width: 200px;
        margin-right: 10px;
    }
    .el-select{
        float: left;
    }
    /deep/.el-input__inner{
        width: 200px;
        height: 32px !important;
        line-height: 32px !important;
    }
    .el-button{
        float: left;
        margin-left: 12px;
    }
    .push_chart{
        margin-left: 0 !important;
    }
    .more_search{
        color: #185BCE;
        cursor: pointer;
    }
    .right{
        float: right;
        height: 100%;
        line-height: 32px;
        color: #185BCE;
        >p{
            float: left;
            margin-right: 16px;
            cursor: pointer;
        }
        >i{
            cursor: pointer;
        }
    }
}
.header_title{
    width: 141px !important;
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
}
.search_btn{
    margin-left: 21px;
    width: 84px;
}
.main-wrapper{
    height: 100%;
}
.allMenu{
    font-weight: 600;
    color: #333333;
}
/deep/.el-submenu__title{
    font-size: 14px !important;
    font-weight: 600;
    color: #333333;
    -webkit-line-clamp: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
}
/deep/.el-submenu__title i {
    color: #000000;
    font-weight: 600;
}
.el-menu-item.is-active{
    background: #EDF4FF !important;
    color: #185BCE !important;
}
.chart_box{
    width: 100%;
    background: #ffffff;
    box-sizing: border-box;
}
.el-main{
    height: 684px;
    padding: 24px !important;
    box-sizing: border-box;
}

.table-box {
    flex: 1;
    display: flex;
    overflow: hidden;
}
.page-box{
    margin: 30px 0;
}
.el-select{
    height: 32px;
    margin-bottom: 12px;
    /deep/.el-input__icon{
        line-height: 32px !important;
    }
    /deep/.el-input__inner{
    width: 180px;
    height: 32px !important;
    line-height: 32px !important;
}
}
.header /deep/.el-input__inner{
    width: 240px;
    height: 32px !important;
    line-height: 32px !important;
}
/deep/.el-textarea{
    margin-bottom: 12px;
}
/deep/.el-tooltip__popper {
    max-height: 326px !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 24;
}

</style>